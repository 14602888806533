<template>
    <div class="channel-contents-container">
        <div class="card shadow-sm p-0 rounded-0">
            <div class="header shadow-sm p-2 text-right border-bottom">
                <div>
                </div>
                <search
                    :searchText="query"
                    :media_type="selected"
                    @change="load_content_channel"
                />
            </div>
            <div class="content-div">
                 <scroll-pagination
                        :scrollFunction="handleScroll"
                        :loading="loading"
                        :page="page"
                        :total_pages="total_pages"
                    >
                    <draggable
                        class="dragabble-content-div"
                        v-model="contents"
                        :clone="clone"
                        v-bind="getOptions()"
                    >
                        <content-vue
                            v-for="content in contents"
                            v-show="contents.length"
                            :key="'content_' + content.id"
                            :content="content"
                            :show_action="false"
                        />
                    </draggable>
                </scroll-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import ContentVue from "../../library/LibraryContent.vue";
    import ContentService from "../../services/ContentService";
    import Search from "../../components/Search";
    import Draggable from "vuedraggable";
    import ChannelService from "../../services/ChannelService";
    export default {
        props: {
            channel: {
                type: Object,
                default: { id: null, media_list: [] },
            },
        },

        data() {
            return {
                contents: [],
                media_type: "Content",
                selected: "Content",
                query: null,
                loading: false,
                total_pages: 0,
                page: 1,
            };
        },

        components: {
            ContentVue,
            Search,
            Draggable,
        },

        mounted() {
            this.fetchContents();
        },

        methods: {
            fetchContents: function (query) {
                let _this = this
                let query_params = {query: query, page: this.page}
                this.loading = true;
                ContentService.list(query_params)
                    .then((response) => {
                        if(_this.page == 1){
                            _this.contents = []
                        }
                        _this.contents.push(...response.data.contents);
                        _this.loading = false;
                        _this.total_pages = response.data.total_pages;
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            },
            fetchChannels: function (query) {
                let _this = this
                let query_params = {add: 1, query: query, page: this.page}
                if(this.channel.id != null){
                    query_params['id'] = this.channel.id
                }
                this.loading = true;
                ChannelService.list(query_params)
                    .then((response) => {
                        if(_this.page == 1){
                            _this.contents = []
                        }
                        _this.contents = response.data.channels;
                        _this.loading = false;
                        _this.total_pages = response.data.total_pages;
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            },
            change_selection: function () {
                this.load_content_channel();
            },

            handleScroll: function () {
                this.page++;
                if (this.selected == "Content") {
                    this.fetchContents(this.query);
                } else {
                    this.fetchChannels(this.query);
                }
            },

            load_content_channel: function (query) {
                this.contents = [];
                this.page = 1;
                this.query = query;
                if (this.selected == "Content") {
                    this.fetchContents(query);
                } else {
                    this.fetchChannels(query);
                }
            },

            clone: function (content) {
                let duration = 10;
                let _this = this;
                if (
                    content.content_type != null &&
                    (content.content_type.startsWith("video") ||
                        content.content_type.startsWith("audio"))
                ) {
                    duration = content.duration;
                }
                return {
                    position: _this.contents.length,
                    duration: duration,
                    resize_mode: content.resize_mode,
                    name: content.name,
                    item_id: content.id,
                    item_type:
                        this.selected == "Content"
                            ? "User::Content"
                            : "Account::Channel",
                    type: content.type,
                    widget: content.widget,
                    img_url: content.img_url,
                    zone_id: null,
                    schedules: [{ dates: [], times: [], week_days: {} }],
                };
            },

            getOptions: function () {
                return {
                    group: { name: "media", pull: "clone", put: false },
                };
            },
        },
    };
</script>

<style lang="scss">
.channel-contents-container {
    display: flex;
    flex: 0.6;
    background-color: rgb(76, 0, 255);
    > .card {
        flex: auto;
        .header {
            div:first-child {
                flex: 0.2;
                min-width: 200px;
                display: flex;
                .custom-select {
                    width: auto;
                    margin: auto 0px;
                }
            }
            .search {
                margin: auto;
                flex: 0.4;
                min-width: 200px;
            }
        }
        .content-div {
            overflow: auto;
            height: 100%;
            display: flex;
            margin-bottom: -50px;
        }
        .dragabble-content-div {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
</style>
